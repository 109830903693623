import React from "react";
import heroBg from "../images/hero-bg2.jpg";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section className="min-h-screen bg-gradient-to-l from-blue-500 to-blue-700 relative bg-cover" style={{ backgroundImage: `url('${heroBg}')`, backgroundPosition: '100%' }}>
        <div className="container mx-auto py-36">
          <h1 className="text-center text-6xl font-bold text-white">404</h1>
          <h2 className="text-2xl text-center text-indigo-300"> Whoops! Looks like this page doesn't exist...</h2>
          <p className="text-center mt-4 text-indigo-100"><Link className="underline" to="/">Click here</Link> to go back to the homepage.</p>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage;
